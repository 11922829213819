console.group("inicializando o pack css do app")

/* !! #6151: sn - deixei comentado os assets do painel para isolar o build do site durante a portabilidade */


import './recyclable.scss'
import './application.scss'
import './components/base'
import './components/console'
import './components/admin_messages'
import './components/help_items'
import './components/contracts'
import './components/dash'
import './components/console_contracts'
import './components/start'
import './components/users'
import './components/app_themes'
import './components/changelog'
import './components/items'
import './components/services'
import './components/providers'

import './overrides'

console.groupEnd();
